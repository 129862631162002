/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/iframe-has-title */
import './App.less';
import $ from 'jquery';
import React, { useState, useEffect, useRef } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export default function Collection() {
  let sliderRef = useRef(null);
  const [list, setList] = useState([])
  const [iframeUrl,setIframeUrl] = useState(list.length > 0 ? list[0].src : '')
  const [width, setWidth] = useState();
  const [settings, setSetting] = useState({
    // dots: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
  })

  useEffect(() => {
    getListData()
    // 设置屏幕监听
    window.addEventListener("resize", handleWindowResize)
    return () => {
        window.removeEventListener("resize", handleWindowResize)
    }
  }, [])

  useEffect(() => {
    if(list.length > 0) {
      sliderRef.slickPrev();
    }
  }, [width])

  useEffect(() => {
    var userAgentStr = window.navigator.userAgent;
    if(!(/DingTalk/.test(userAgentStr))) {
      alert('请从钉钉内访问');
      window.opener.cloese();
    }
  },[])

  const getListData = () => {
    // let url = 'https://geyuan-back.oss-cn-beijing.aliyuncs.com/AI%E5%AE%9D%E5%BA%93/data.json'
    let url = 'https://objectstorageapi.bja.sealos.run/a23i8mr4-dsb/AI宝库/data.json'
    fetch(url)
    .then((res) => {return res.json()})
    .then((data) => {
      console.log(data.list)
      setList(data.list)
      let localStr = localStorage.getItem('localIframe');
      if(localStr !== null) {
        setIframeUrl(localStr)
      } else {
        setIframeUrl(data.list.length > 0 ? data.list[0].src : '')
      }
      setTimeout(function () {
        setSliderOpe()
      }, 1000)
    })
    .catch(err => {
      console.log('err---', err)
    })
  }

  const sliderMap = list.map((item, index) => 
    <div key={index} style={{width: 120}}>
      <div className='slider_chi' onClick={() => {
        setIframeUrl(list[index].src)
        localStorage.setItem('localIframe', list[index].src)
      }}>
        <img src={item.img}/>
        <p>
          {item.name}
        </p>
      </div>
    </div>
  )

  const handleWindowResize = () => {
    setSliderOpe()
  }
  
  const setSliderOpe = () => {
    let temp = {...settings}
    // 280
    let sliderNum = (document.documentElement.clientWidth) - 280;
    temp.slidesToShow = Math.floor(sliderNum / 120)
    // if(($('.slider_chi').length) <= (Math.floor(sliderNum / 120))) {}
    if(Math.floor(($('.slider_chi').length) / 2) >= ($('.slider_chi').length) - (Math.floor(sliderNum / 120))) {
      if( ($('.slider_chi').length) - (Math.floor(sliderNum / 120)) > 0 ) {
        temp.slidesToScroll = ($('.slider_chi').length) - (Math.floor(sliderNum / 120))
      } else {
        temp.slidesToScroll = ($('.slider_chi').length)
      }
    } else {
      temp.slidesToScroll = Math.floor(($('.slider_chi').length) / 3)
    }
    // console.log(($('.slider_chi').length) - (Math.floor(sliderNum / 120)), '-', $('.slider_chi').length)
    setSetting(temp)
    // $('.slider_chi').length * 120 + 90
    setWidth(sliderNum)
  }

  return (
    // <SwiperDom />
    <div className="collection_con">
      <div className='collection_head'>
        {/* document.location.reload(); */}
        <div className='collection_logo' onClick={() => {window.history.go(0)}}>
          <img src={require('./image/logo.png')} />
          AI百宝箱
        </div>
        <div
          className='slider_box'
          style={{
            width: width + "px",
          }}
        >
          <Slider 
          ref={slider => {
            sliderRef = slider;
          }}
          {...settings}>
            {sliderMap}
          </Slider>
        </div>
      </div>
      <div className='collection_body'>
      <iframe
        name="iframe"
        scrolling="auto"
        align="left" 
        src={iframeUrl}
      />
      </div>
    </div>
  )
}